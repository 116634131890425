<template>

  <v-app>

    <v-app-bar
      color="blue darken-3"
      app
      dark>

      <v-spacer></v-spacer>

      <v-btn icon link @click="signout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main
      >
      <router-view />
    </v-main>

  </v-app>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Console from '@/console'

export default {

  data() {
    return {
      drawer: true,

      clientName: '',

      items: [],
    }
  },

  created() {
    this.items = this.menuItems
  },

  computed: {
    ...mapGetters('Actor', ['id']),
    ...mapGetters('Account', [ 'name', 'initials' ]),
    ...mapGetters([ 'canManageUsers', 'canManageContacts' ]),

    isMini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },

    employeeName() {
      return this.employeeInfo?.name ?? this.name
    },

    clientNames: {
      get() {
        return [{text: "hello", value: "no"}]

        //return this.clients.map(c => { return {
        //  text: c.client_name,
        //  value: c.actor_id
        //}})
      },
      set() { /* This is dumb */ }
    },

    actorId: {
      get() { return this.id },
      set (actorId) {
        this.setActorId(actorId)
        .then(() => this.loadEmployee())
        .catch(error => Console.error('Could not properly change client/employee', error))
      }
    },

    isInIframe: () => window.self !== window.top,

    menuItems() {
      let items = [
        { name: 'channels', title: 'Channels', icon: 'mdi-map-marker-path' },
        { name: 'users', title: 'Users', icon: 'mdi-account-multiple' },
      ]

      if (this.canManageUsers) {
        items.push({ name: 'users', title: 'Users', icon: 'mdi-account-cog-outline' })
      }

      if (this.canManageContacts) {
        items.push({ name: 'contacts', title: 'Contacts', icon: 'mdi-account-cog-outline' })
      }

      Console.log('returning menuItems', items)

      return items
    },

  },

  methods: {
    ...mapActions('Auth', ['setActorId', 'signOut']),
    ...mapActions('Employee', ['loadEmployee', 'getEmployee', 'getBenefitPlans', 'getPaycard']),

    async signout() {
      await this.signOut()
      return this.$router.push( { name: 'sign-in' } )
    }
  },

  watch: {
    //clients: {
    //  handler() {
    //    const clients = this.clients

    //    if (!clients) return

    //    this.clientNames = this.clients.map(c => c.client_name)

    //    if (! this.clientName && this.clientNames.length > 0) {
    //      this.clientName = this.clientNames?.[0]
    //    }
    //  }
    //},

    canManageUsers: {
      handler() {
        this.items = this.menuItems
      }
    }
  }
}
</script>

<style scoped>
</style>
